import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countdownTime: Date.now() + 60 * 1000,
  isCountdownActive: true,
};

export const countdownSlice = createSlice({
  name: "countdownState",
  initialState,
  reducers: {
    startCountdown(state, action = 60) {
      state.countdownTime = Date.now() + action.payload * 1000;
    },
    completeCountdown(state) {
      state.isCountdownActive = false;
    },
    resetCountdown(state, action = 60) {
      state.countdownTime = Date.now() + action.payload * 1000;
      state.isCountdownActive = true;
    },
  },
});

export const { startCountdown, completeCountdown, resetCountdown } =
  countdownSlice.actions;
export const countdownData = (state) => state.countdownState;

export default countdownSlice;
