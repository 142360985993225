import * as React from "react";

function CopySuccess({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={0.4}
        d="M18.525 2.168h-4.55c-3.737 0-5.254 1.484-5.297 5.146h3.347c4.55 0 6.663 2.112 6.663 6.662v3.348c3.661-.044 5.146-1.56 5.146-5.298v-4.55c0-3.791-1.517-5.308-5.309-5.308z"
        fill="#fff"
      />
      <path
        d="M12.024 8.668h-4.55c-3.791 0-5.308 1.517-5.308 5.308v4.55c0 3.792 1.517 5.309 5.308 5.309h4.55c3.792 0 5.309-1.517 5.309-5.309v-4.55c0-3.791-1.517-5.308-5.309-5.308zm1.29 6.12l-4.02 4.02a.77.77 0 01-.552.227.77.77 0 01-.553-.227l-2.015-2.015a.772.772 0 010-1.094.772.772 0 011.095 0L8.73 17.16l3.478-3.477a.772.772 0 011.094 0 .785.785 0 01.01 1.105z"
        fill="#fff"
      />
    </svg>
  );
}

export default CopySuccess;
