import { Box, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaEnvelope, FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import * as yup from "yup";
import Button from "../../components/Button";
import Input, { InputPhone } from "../../components/Input";
import BaseModal from "../../components/Modals/Modal";
import { useSendInviteToVendorMutation } from "../../redux/api/builder/builder";
import { userData } from "../../redux/store/store";
import useModalHandler from "../Modals/SuccessModal";
import { handleError } from "../../utility/helpers";
import { trackEvent } from "../../utility/mix-panel";

/**
 *
 * @param {{isOpen: Boolean, closeModal: Function}} param0
 * @returns
 */

const InviteVendorForm = ({ isOpen, closeModal }) => {
  const [vendor, setVendor] = useState();
  const userInfo = useSelector(userData);
  const { handleSuccessModal, ModalComponent } = useModalHandler();

  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [sendInviteToVendor, { isLoading, isSuccess, error, isError }] =
    useSendInviteToVendorMutation();

  const formSchema = yup.object({
    name: yup
      .string()
      .required("Name is required")
      .test("notEmpty", "This field cannot be empty", (value) => {
        return !/^\s*$/.test(value);
      }),
    email: yup.string().required("Email is required").email(),
    phone: yup
      .string()
      .required("Phone number is required")
      .test("notEmpty", "This field cannot be empty", (value) => {
        return !/^\s*$/.test(value);
      }),
  });

  const methods = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
    resolver: yupResolver(formSchema),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    const formData = {
      toName: data.name,
      toEmail: data.email,
      Location: "Lagos Nigeria",
      inviteeName: userInfo.data.userName,
    };

    setVendor(data);

    sendInviteToVendor(formData);
  };

  const toast = useToast();

  useEffect(() => {
    if (isError) {
      handleError(error);
    }

    if (isSuccess) {
      toast({
        description: "Invite sent!",
        status: "success",
      });

      closeModal();
      reset();
      handleSuccessModal(
        <Box py={"10px"}>
          <Text align={"center"} fontSize={"24px"} fontWeight={700}>
            Vendor invited successfully
          </Text>
        </Box>,
      );

      trackEvent("supplier_invited_in_category", {
        vendorName: vendor?.name,
        vendorEmail: vendor?.email,
        user,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  return (
    <>
      <BaseModal
        onClose={closeModal}
        isOpen={isOpen}
        title="Invite Suplier"
        subtitle="Fill out the form below with the details of the vendor to invite."
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box my="20px">
            <Text color={"#333"} fontSize={"14px"} mb={"8px"}>
              Name{" "}
              <Text as={"span"} color={"red"}>
                *
              </Text>
            </Text>
            <Controller
              name="name"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box>
                  <Input
                    type={"text"}
                    value={value}
                    onChange={onChange}
                    placeholder="John Jameson"
                    leftIcon={<FaUser />}
                  />
                  <div style={{ color: "red" }}>
                    {errors["name"] ? errors["name"]?.message : ""}
                  </div>
                </Box>
              )}
            />
          </Box>
          <Box my="20px">
            <Text color={"#333"} fontSize={"14px"} mb={"8px"}>
              Email{" "}
              <Text as={"span"} color={"red"}>
                *
              </Text>
            </Text>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box>
                  <Input
                    type={"email"}
                    value={value}
                    onChange={onChange}
                    placeholder="j.jameson@mybusiness.com"
                    leftIcon={<FaEnvelope />}
                  />
                  <div style={{ color: "red" }}>
                    {errors["email"] ? errors["email"]?.message : ""}
                  </div>
                </Box>
              )}
            />
          </Box>
          <Box my="20px">
            <Text color={"#333"} fontSize={"14px"} mb={"8px"}>
              Phone Number{" "}
              <Text as={"span"} color={"red"}>
                *
              </Text>
            </Text>
            <Controller
              name="phone"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box>
                  <InputPhone value={value} onChange={onChange} />
                  <div style={{ color: "red" }}>
                    {errors["phone"] ? errors["phone"]?.message : ""}
                  </div>
                </Box>
              )}
            />
          </Box>

          <Box mt={"66px"}>
            <Button
              full
              isSubmit
              fontWeight="700"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Send Invite
            </Button>
          </Box>
        </form>
      </BaseModal>

      {ModalComponent}
    </>
  );
};

export default InviteVendorForm;
