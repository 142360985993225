import React from "react";

export default function DashboardIcon({
  width = "24",
  height = "24",
  fill = "#999999",
  opacity = "0.56",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z"
          fill={fill}
        />
        <path
          d="M18.6675 2H16.7675C14.5875 2 13.4375 3.15 13.4375 5.33V7.23C13.4375 9.41 14.5875 10.56 16.7675 10.56H18.6675C20.8475 10.56 21.9975 9.41 21.9975 7.23V5.33C21.9975 3.15 20.8475 2 18.6675 2Z"
          fill={fill}
        />
        <path
          d="M18.6675 13.4297H16.7675C14.5875 13.4297 13.4375 14.5797 13.4375 16.7597V18.6597C13.4375 20.8397 14.5875 21.9897 16.7675 21.9897H18.6675C20.8475 21.9897 21.9975 20.8397 21.9975 18.6597V16.7597C21.9975 14.5797 20.8475 13.4297 18.6675 13.4297Z"
          fill={fill}
        />
        <path
          d="M7.24 13.4297H5.34C3.15 13.4297 2 14.5797 2 16.7597V18.6597C2 20.8497 3.15 21.9997 5.33 21.9997H7.23C9.41 21.9997 10.56 20.8497 10.56 18.6697V16.7697C10.57 14.5797 9.42 13.4297 7.24 13.4297Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
