import { Box, Checkbox, Flex, Heading, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import GoogleImage from "../../assets/images/google.svg";
import Button from "../../components/Button";
import Input from "../../components/Input";
import ContactAdmin from "../../layouts/onboardingModals/contactAdmin";
import {
  useLoginUserMutation,
  useLoginUserWithSSOMutation,
} from "../../redux/api/auth/authSlice";
import { handleError, handleSuccess } from "../../utility/helpers";
import SERVICES from "../../utility/webservices";
import AuthLayout from "./AuthLayout";
import { getTokenInfo } from "./services/api/googleLoginAPI";
import OTPScreen from "./OtpScreen";
import { isArray } from "lodash";
import { mixpanelIdentify } from "../../utility/mix-panel";

const UserLogin = () => {
  let history = useHistory();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [goToEmailVerification, setGoToEmailVerification] =
    React.useState(false);

  const registerSchema = yup.object({
    email: yup.string().required("Email is required").email(),
    password: yup.string().required("Password is required"),
  });

  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(registerSchema),
  });

  // 👇 Calling the Login Mutation
  const [loginUser, { isLoading, isSuccess, error, isError, data }] =
    useLoginUserMutation();
  const [
    loginUserWithSSO,
    {
      isLoading: ssoIsLoading,
      isSuccess: ssoIsSuccess,
      error: ssoError,
      isError: ssoIsError,
      data: ssoData,
    },
  ] = useLoginUserWithSSOMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const loginSuccess = async () => {
    handleSuccess("Login successful");
    setLoading(false);
  };

  React.useEffect(() => {
    if (isSuccess || ssoIsSuccess) {
      if (data?.data?.userType === "BUILDER") {
        history.push("/builder/dashboard");
      } else if (data?.data?.userType === "SUPPLIER") {
        history.push("/vendor/dashboard");
      } else if (data?.data?.userType === "ADMIN") {
        history.push("/admin/dashboard");
      } else if (data?.data?.userType === "FUND_MANAGER") {
        history.push("/fund-manager/dashboard");
      }

      loginSuccess();
      mixpanelIdentify(data.data);
    }

    const loginError = error || ssoError;

    const errMsg = isArray(loginError?.data?.message)
      ? loginError?.data?.message[0]
      : loginError?.data?.message;

    if (isError || ssoIsError) {
      if (error || ssoError) handleError(loginError);
      setLoading(false);

      if (
        errMsg ===
        "email not verified, kindly verify with the otp sent to your email"
      ) {
        setGoToEmailVerification(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, ssoIsLoading]);

  const onSubmit = (data) => {
    setLoading(true);
    loginUser({ ...data, email: data.email.toLowerCase() });
    setPassword(data?.password);
    setEmail(data?.email.toLowerCase());
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userInfo = await getTokenInfo(tokenResponse.access_token);

        loginUserWithSSO({
          email: userInfo?.email,
        });
      } catch (error) {
        console.log(error);
      }
    },
    onError: (error) => console.log(error),
  });

  return (
    <>
      {isSuccess || goToEmailVerification ? (
        <OTPScreen email={email} password={password} />
      ) : (
        <AuthLayout>
          <Heading mt="40px" color="#F5862E" fontSize={["24px", "34px"]}>
            Welcome back!
          </Heading>
          <Text color="#999999" my="10px">
            You can login as Project Sponsor, Contractor, or a Vendor.
          </Text>

          <Box mt="30px">
            <hr />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box fontSize="14px" my="40px">
              <Box my={"20px"}>
                <Text>
                  Email Address <span style={{ color: "red" }}>*</span>
                </Text>
                <Controller
                  control={control}
                  defaultValue=""
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <Box w={"100%"}>
                      <Input
                        placeholder="yourname@example.com"
                        value={value}
                        onChange={onChange}
                        leftIcon={<FaEnvelope />}
                      />
                      <div style={{ color: "red" }}>
                        {errors["email"] ? errors["email"]?.message : ""}
                      </div>
                    </Box>
                  )}
                />
              </Box>
              <Box my="20px">
                <Text>
                  Password <span style={{ color: "red" }}>*</span>
                </Text>
                <Controller
                  name="password"
                  defaultValue=""
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Box>
                      <Input
                        type={"password"}
                        value={value}
                        onChange={onChange}
                        placeholder="Enter your Password"
                        leftIcon={<FaLock />}
                      />
                      <div style={{ color: "red" }}>
                        {errors["password"] ? errors["password"]?.message : ""}
                      </div>
                    </Box>
                  )}
                />
              </Box>
              <Flex justify="space-between" my="20px">
                <Checkbox colorScheme="orange">
                  <span style={{ fontSize: "14px" }}>Remember me</span>
                </Checkbox>
                <Link to="/auth/forgot-password">
                  <span
                    style={{
                      color: "#F5862E",
                      marginLeft: "5px",
                    }}
                  >
                    Forgot Password?
                  </span>
                </Link>
              </Flex>
            </Box>

            <Box>
              <Button full isSubmit isLoading={loading}>
                Sign In
              </Button>
              <Flex my="20px" alignContent={"center"} alignItems={"center"}>
                <Box w={"45%"}>
                  <hr />
                </Box>
                <Text color={"#303030"} mx="20px">
                  Or
                </Text>
                <Box w={"45%"}>
                  <hr />
                </Box>
              </Flex>

              {/* <Button
            leftIcon={<Image src={GoogleImage} alt="" />}
            full
            variant="solid"
            // background="#fff"
            border="1px solid #303030"
            color="#303030"
            onClick={loginWithGoogle}
          >
            Continue with Google
          </Button> */}

              <Box textAlign="center" mt="20px">
                No account yet?{" "}
                <Link to="/">
                  <span
                    style={{
                      color: "#F5862E",
                      marginLeft: "5px",
                      fontWeight: 600,
                    }}
                  >
                    Create One
                  </span>
                </Link>
              </Box>
            </Box>
          </form>

          <ContactAdmin />
        </AuthLayout>
      )}
    </>
  );
};

export default UserLogin;
