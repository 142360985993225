import * as React from "react";

function Edit({ fill = "#565759", width = "16px", height = "16px" }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 14.668H2a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h12c.273 0 .5.227.5.5s-.227.5-.5.5zM12.677 2.318c-1.294-1.293-2.56-1.326-3.887 0l-.807.807c-.067.067-.093.173-.067.267a5.42 5.42 0 003.687 3.686c.027.007.053.014.08.014.074 0 .14-.027.194-.08l.8-.807c.66-.653.98-1.287.98-1.927.006-.66-.314-1.3-.98-1.96zM10.406 7.688a5.867 5.867 0 01-.987-.573 4.026 4.026 0 01-.373-.287.814.814 0 01-.114-.1 5.639 5.639 0 01-.686-.693c-.02-.013-.054-.06-.1-.12-.067-.08-.18-.213-.28-.367a3.66 3.66 0 01-.26-.393c-.107-.18-.2-.36-.294-.547-.122-.262-.466-.34-.67-.135l-3.75 3.749a.803.803 0 00-.186.366l-.36 2.554c-.067.453.06.88.34 1.167.24.233.573.36.933.36.08 0 .16-.007.24-.02l2.56-.36c.12-.02.287-.1.367-.187l3.755-3.755c.2-.2.125-.546-.135-.659z"
        fill={fill}
      />
    </svg>
  );
}

export default Edit;
