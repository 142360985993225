import React from "react";

export default function FundManagerIcon({
  width = "24",
  height = "24",
  fill = "#999999",
  opacity = "0.56",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z"
          fill={fill}
        />
        <path
          d="M17.0809 14.1509C14.2909 12.2909 9.74094 12.2909 6.93094 14.1509C5.66094 15.0009 4.96094 16.1509 4.96094 17.3809C4.96094 18.6109 5.66094 19.7509 6.92094 20.5909C8.32094 21.5309 10.1609 22.0009 12.0009 22.0009C13.8409 22.0009 15.6809 21.5309 17.0809 20.5909C18.3409 19.7409 19.0409 18.6009 19.0409 17.3609C19.0309 16.1309 18.3409 14.9909 17.0809 14.1509ZM14.0009 18.1309H12.7509V19.3809C12.7509 19.7909 12.4109 20.1309 12.0009 20.1309C11.5909 20.1309 11.2509 19.7909 11.2509 19.3809V18.1309H10.0009C9.59094 18.1309 9.25094 17.7909 9.25094 17.3809C9.25094 16.9709 9.59094 16.6309 10.0009 16.6309H11.2509V15.3809C11.2509 14.9709 11.5909 14.6309 12.0009 14.6309C12.4109 14.6309 12.7509 14.9709 12.7509 15.3809V16.6309H14.0009C14.4109 16.6309 14.7509 16.9709 14.7509 17.3809C14.7509 17.7909 14.4109 18.1309 14.0009 18.1309Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
