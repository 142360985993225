import React from "react";

export default function TeamIcon({
  width = "24",
  height = "24",
  fill = "#999999",
  opacity = "0.56",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
          fill={fill}
        />
        <path
          d="M14.0809 14.1509C11.2909 12.2909 6.74094 12.2909 3.93094 14.1509C2.66094 15.0009 1.96094 16.1509 1.96094 17.3809C1.96094 18.6109 2.66094 19.7509 3.92094 20.5909C5.32094 21.5309 7.16094 22.0009 9.00094 22.0009C10.8409 22.0009 12.6809 21.5309 14.0809 20.5909C15.3409 19.7409 16.0409 18.6009 16.0409 17.3609C16.0309 16.1309 15.3409 14.9909 14.0809 14.1509Z"
          fill={fill}
        />
        <path
          d="M19.9894 7.3401C20.1494 9.2801 18.7694 10.9801 16.8594 11.2101C16.8494 11.2101 16.8494 11.2101 16.8394 11.2101H16.8094C16.7494 11.2101 16.6894 11.2101 16.6394 11.2301C15.6694 11.2801 14.7794 10.9701 14.1094 10.4001C15.1394 9.4801 15.7294 8.1001 15.6094 6.6001C15.5394 5.7901 15.2594 5.0501 14.8394 4.4201C15.2194 4.2301 15.6594 4.1101 16.1094 4.0701C18.0694 3.9001 19.8194 5.3601 19.9894 7.3401Z"
          fill={fill}
        />
        <path
          d="M21.9922 16.5904C21.9122 17.5604 21.2922 18.4004 20.2522 18.9704C19.2522 19.5204 17.9922 19.7804 16.7422 19.7504C17.4622 19.1004 17.8822 18.2904 17.9622 17.4304C18.0622 16.1904 17.4722 15.0004 16.2922 14.0504C15.6222 13.5204 14.8422 13.1004 13.9922 12.7904C16.2022 12.1504 18.9822 12.5804 20.6922 13.9604C21.6122 14.7004 22.0822 15.6304 21.9922 16.5904Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
