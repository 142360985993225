import React from "react";

const People = ({
  width = "24",
  height = "24",
  fill = "#999999",
  opacity = "0.56",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g opacity={opacity}>
        <path
          d="M17.5291 7.77C17.4591 7.76 17.3891 7.76 17.3191 7.77C15.7691 7.72 14.5391 6.45 14.5391 4.89C14.5391 3.3 15.8291 2 17.4291 2C19.0191 2 20.3191 3.29 20.3191 4.89C20.3091 6.45 19.0791 7.72 17.5291 7.77Z"
          fill={fill}
        />
        <path
          d="M20.7916 14.7004C19.6716 15.4504 18.1016 15.7304 16.6516 15.5404C17.0316 14.7204 17.2316 13.8104 17.2416 12.8504C17.2416 11.8504 17.0216 10.9004 16.6016 10.0704C18.0816 9.8704 19.6516 10.1504 20.7816 10.9004C22.3616 11.9404 22.3616 13.6504 20.7916 14.7004Z"
          fill={fill}
        />
        <path
          d="M6.43625 7.77C6.50625 7.76 6.57625 7.76 6.64625 7.77C8.19625 7.72 9.42625 6.45 9.42625 4.89C9.42625 3.29 8.13625 2 6.53625 2C4.94625 2 3.65625 3.29 3.65625 4.89C3.65625 6.45 4.88625 7.72 6.43625 7.77Z"
          fill={fill}
        />
        <path
          d="M6.54719 12.8506C6.54719 13.8206 6.75719 14.7406 7.13719 15.5706C5.72719 15.7206 4.25719 15.4206 3.17719 14.7106C1.59719 13.6606 1.59719 11.9506 3.17719 10.9006C4.24719 10.1806 5.75719 9.89059 7.17719 10.0506C6.76719 10.8906 6.54719 11.8406 6.54719 12.8506Z"
          fill={fill}
        />
        <path
          d="M12.1169 15.87C12.0369 15.86 11.9469 15.86 11.8569 15.87C10.0169 15.81 8.54688 14.3 8.54688 12.44C8.55687 10.54 10.0869 9 11.9969 9C13.8969 9 15.4369 10.54 15.4369 12.44C15.4269 14.3 13.9669 15.81 12.1169 15.87Z"
          fill={fill}
        />
        <path
          d="M8.86687 17.9406C7.35687 18.9506 7.35687 20.6106 8.86687 21.6106C10.5869 22.7606 13.4069 22.7606 15.1269 21.6106C16.6369 20.6006 16.6369 18.9406 15.1269 17.9406C13.4169 16.7906 10.5969 16.7906 8.86687 17.9406Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default People;
