import {
  Flex,
  FormControl,
  FormLabel,
  Input as InputBox,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Textarea,
} from "@chakra-ui/react";
import { capitalize } from "lodash";
import React, { forwardRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import PhoneInput from "react-phone-input-2";
import CalendarIcon from "./Icons/Calendar";
import DatePicker from "react-datepicker";
import { NumericFormat } from "react-number-format";

export default function Input({
  id = "",
  label = "",
  isRequired = false,
  type = "text",
  readOnly = false,
  onChange = null,
  isDisabled = false,
  size = "lg",
  placeholder = `Enter ${label.toLowerCase()}`,
  pl = 0,
  rightIcon = null,
  leftIcon = null,
  css,
  min = null,
  max = null,
  ...rest
}) {
  const [active, setActive] = useState(false);
  const [inputType, setInputType] = useState(type);

  return (
    <FormControl
      id={id}
      isReadOnly={readOnly}
      isDisabled={isDisabled}
      isRequired={isRequired}
      pos="relative"
    >
      {label && (
        <FormLabel
          mb="0"
          textTransform="capitalize"
          fontWeight="400"
          fontSize="15px"
        >
          {capitalize(label)}
        </FormLabel>
      )}

      <InputGroup>
        <InputBox
          onChange={onChange}
          placeholder={
            inputType === "password" ? "⏺ ⏺ ⏺ ⏺ ⏺ ⏺ ⏺ ⏺ ⏺ ⏺ ⏺ ⏺" : placeholder
          }
          type={inputType}
          border="1px solid #999999"
          _focus={{ borderColor: "#F5862E", color: "#999" }} //"#F5862E"
          size={size}
          // min={inputType === "date" ? moment().format("YYYY-MM-DD") : null}
          min={min}
          max={max}
          fontSize="16px"
          rounded="8px"
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          css={css}
          {...rest} // passing other html input props
        />
        {rightIcon && (
          <InputRightElement
            color={active ? "#F5862E" : "#999999"}
            children={rightIcon}
            h="100%"
          />
        )}
        {leftIcon && (
          <InputLeftElement
            color={active ? "#F5862E" : "#999999"}
            children={leftIcon}
            h="100%"
          />
        )}
        {isDisabled && type === "date" && (
          <InputRightElement
            color={active ? "#F5862E" : "#999999"}
            children={<CalendarIcon />}
            h="100%"
          />
        )}
        {type === "password" && (
          <InputRightElement
            h="100%"
            cursor={"pointer"}
            onClick={() => {
              if (inputType === "password") {
                setInputType("text");
              } else {
                setInputType("password");
              }
            }}
          >
            <Flex justify="center" color={"#999999"} alignItems="center">
              {inputType !== "text" ? <FaEye /> : <FaEyeSlash />}
            </Flex>
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  );
}

const InputPhone = ({
  value,
  id = "",
  onChange,
  label,
  isRequired,
  isDisabled,
  readOnly,
  ...rest
}) => {
  const handleKeyPress = (event) => {
    if (event.key === "-") event.preventDefault();
    if (event.key === "e") event.preventDefault();
    if (event.key === ".") event.preventDefault();
  };
  return (
    <FormControl
      id={id}
      isReadOnly={readOnly}
      isDisabled={isDisabled}
      isRequired={isRequired}
      pos="relative"
    >
      {label && (
        <FormLabel
          mb="0"
          textTransform="capitalize"
          fontWeight="400"
          fontSize="15px"
        >
          {capitalize(label)}{" "}
        </FormLabel>
      )}

      <Input
        leftIcon={<FaPhone />}
        placeholder="Enter phone number"
        type="number"
        value={value}
        onChange={onChange}
        pattern="[0-9+]*"
        onKeyPress={handleKeyPress}
      />
    </FormControl>
  );
};

export { InputPhone };

const TextArea = ({
  label = "",
  isRequired = false,
  readOnly = false,
  isDisabled = false,
  placeholder = `Enter ${label.toLowerCase()}`,
  value,
  onChange,
  h,
  id = "",
  ...rest
}) => {
  return (
    <FormControl
      id={id}
      isReadOnly={readOnly}
      isDisabled={isDisabled}
      isRequired={isRequired}
      pos="relative"
    >
      {label && (
        <FormLabel
          mb="0"
          textTransform="capitalize"
          fontWeight="400"
          fontSize="15px"
        >
          {capitalize(label)}
        </FormLabel>
      )}
      <Textarea
        placeholder={placeholder}
        value={value}
        _focus={{ borderColor: "#F5862E", color: "#999" }}
        border={"1px solid #999999"}
        onChange={onChange}
        rounded="8px"
        p="10px 15px"
        h={h}
        {...rest}
      />
    </FormControl>
  );
};

const InputNumber = ({
  label = "",
  isRequired = false,
  readOnly = false,
  isDisabled = false,
  placeholder = `Enter ${label.toLowerCase()}`,
  value,
  thousandSeparator = ",",
  onChange,
  h,
  id = "",
  ...rest
}) => {
  return (
    <FormControl
      id={id}
      isReadOnly={readOnly}
      isDisabled={isDisabled}
      isRequired={isRequired}
      pos="relative"
    >
      {label && (
        <FormLabel
          mb="0"
          textTransform="capitalize"
          fontWeight="400"
          fontSize="15px"
        >
          {capitalize(label)}
        </FormLabel>
      )}
      <NumericFormat
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        thousandsGroupStyle="thousand"
        allowNegative={false}
        thousandSeparator={thousandSeparator}
        customInput={Input}
        isDisabled={isDisabled}
      />
    </FormControl>
  );
};

const ChakraDate = forwardRef(({ value, onClick }, ref) => (
  <Input
    onClick={onClick}
    ref={ref}
    placeholder={"Select date"}
    value={value}
    readOnly
    rightIcon={<CalendarIcon />}
  />
));

const InputDate = forwardRef(
  (
    {
      label = "",
      isRequired = false,
      readOnly = false,
      isDisabled = false,
      placeholder = "Select Date",
      value,
      onChange,
      onClick,
      id = "",
      min,
      max,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControl
        id={id}
        isReadOnly={readOnly}
        isDisabled={isDisabled}
        isRequired={isRequired}
        pos="relative"
      >
        {label && (
          <FormLabel
            mb="0"
            textTransform="capitalize"
            fontWeight="400"
            fontSize="15px"
          >
            {capitalize(label)}
          </FormLabel>
        )}
        <DatePicker
          selected={value}
          onChange={onChange}
          customInput={<ChakraDate placeholder={placeholder} />}
          wrapperClassName="datepicker_width"
          // minDate={min}
          // maxDate={max}
          showMonthDropdown
          showYearDropdown
        />
      </FormControl>
    );
  },
);

export { TextArea, InputNumber, InputDate };
