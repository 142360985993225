import React, { useEffect, useState } from "react";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { RiSearch2Line } from "react-icons/ri";
import BaseTable from "../../../../components/Table";
import EmptyState from "../../../../components/EmptyState";
import moment from "moment";
import Input from "../../../../components/Input";
import Popup from "../../../../components/Popup/Popup";
import PopOverInfo from "../components/popOverInfo";

const ViewBids = ({ data, getRequestData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [tableBody, setTableBody] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const arr = [];
    let counter = 1;

    data?.RfqQuotes?.forEach((item, i) => {
      arr.push({
        SN: `0${counter}`,
        materialName: (
          <Flex align="center">
            {item?.RfqQuote?.Vendor?.createdBy?.businessName}{" "}
            <Popup
              info={<PopOverInfo data={item?.RfqQuote?.Vendor} />}
              fill="#666"
            />
          </Flex>
        ),
        quantity: item?.RfqQuote?.RfqRequestMaterial?.quantity ?? 0,
        amount: new Intl.NumberFormat().format(
          item?.RfqQuote?.RfqRequestMaterial?.budget,
        ),
        estimatedDelivery: moment(item?.RfqQuote?.deliveryDate).format(
          "DD-MM-YYYY",
        ),
        id: item?.id,
      });
      counter++;
    });

    setTableBody(arr);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const tableColumn = [
    "S/N",
    "NAME",
    "QUANTITY",
    "AMOUNT (₦)",
    "ESTIMATED DELIVERY",
  ];

  return (
    <Box my="40px" p={"32px 24px"} background={"#FCF7F6"}>
      <Flex
        direction={["column", "column", "row"]}
        justifyContent={["space-between"]}
        alignItems={["flex-start", "flex-start", "flex-end"]}
        gap={2}
      >
        <Box fontSize="20px">
          <Text fontWeight="600" color="secondary">
            Bids
          </Text>
          <Text fontSize="14px">
            These are bids that have been submitted by various vendors.
          </Text>
        </Box>

        <Spacer />
        <Box width="max-content" position={"relative"}>
          <Flex gap={"1rem"}>
            <Input
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              leftIcon={<RiSearch2Line />}
            />
          </Flex>
        </Box>
      </Flex>

      <Box mt="20px">
        <BaseTable
          tableColumn={tableColumn}
          tableBody={tableBody}
          isLoading={isLoading}
          empty={
            <EmptyState>
              <Text>
                No{" "}
                <Text as="span" color="secondary">
                  Bids
                </Text>{" "}
                yet
              </Text>
            </EmptyState>
          }
        />
      </Box>
    </Box>
  );
};

export default ViewBids;
