import * as React from "react";

export default function WalletPlus({
  fill = "#999999",
  width = "24",
  height = "24",
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.943 2.212l-2.41 5.61h-2.41c-.4 0-.79.03-1.17.11l1-2.4.04-.09.06-.16c.03-.07.05-.13.08-.18 1.16-2.69 2.46-3.53 4.81-2.89zM18.727 8.09l-.02-.01c-.6-.17-1.21-.26-1.83-.26h-6.26l2.25-5.23.03-.07c.14.05.29.12.44.17l2.21.93c1.23.51 2.09 1.04 2.62 1.68.09.12.17.23.25.36.09.14.16.28.2.43.04.09.07.17.09.26.15.51.16 1.09.02 1.74zM18.292 9.52c-.45-.13-.92-.2-1.41-.2h-9.76c-.68 0-1.32.13-1.92.39a4.894 4.894 0 00-2.96 4.49v1.95c0 .24.02.47.05.71.22 3.18 1.92 4.88 5.1 5.09.23.03.46.05.71.05h7.8c3.7 0 5.65-1.76 5.84-5.26.01-.19.02-.39.02-.59V14.2a4.9 4.9 0 00-3.47-4.68zm-3.79 6.67h-1.75V18c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-1.81h-1.75a.749.749 0 110-1.5h1.75V13c0-.41.34-.75.75-.75s.75.34.75.75v1.69h1.75a.749.749 0 110 1.5z"
        fill={fill}
      />
    </svg>
  );
}
