import * as React from "react";

export default function DeleteIcon({
  width,
  height,
  color = "#999",
  fill = "#999",
  ...props
}) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.05 3.487a74.222 74.222 0 00-3.228-.246v-.007l-.146-.867c-.1-.613-.247-1.533-1.807-1.533H7.122c-1.553 0-1.7.88-1.806 1.527l-.14.853c-.62.04-1.24.08-1.86.14l-1.36.133a.501.501 0 00-.454.547.497.497 0 00.547.447l1.36-.134c3.493-.346 7.013-.213 10.547.14h.053a.505.505 0 00.5-.453.51.51 0 00-.46-.547zM12.821 5.426a.843.843 0 00-.607-.26H3.788a.832.832 0 00-.834.887l.414 6.84c.073 1.013.166 2.28 2.493 2.28h4.28c2.327 0 2.42-1.26 2.493-2.28l.414-6.834a.864.864 0 00-.227-.633zm-3.713 6.407h-2.22a.504.504 0 01-.5-.5c0-.274.226-.5.5-.5h2.22c.273 0 .5.226.5.5 0 .273-.227.5-.5.5zm.56-2.667H6.334a.504.504 0 01-.5-.5c0-.273.227-.5.5-.5h3.334c.273 0 .5.227.5.5s-.227.5-.5.5z"
        fill={color ?? fill}
      />
    </svg>
  );
}
