import React from "react";

export default function UserPlusIcon({
  fill = "#fff",
  width = "16",
  height = "16",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.00065 1.3335C6.25398 1.3335 4.83398 2.7535 4.83398 4.50016C4.83398 6.2135 6.17398 7.60016 7.92065 7.66016C7.97398 7.6535 8.02732 7.6535 8.06732 7.66016C8.08065 7.66016 8.08732 7.66016 8.10065 7.66016C8.10732 7.66016 8.10732 7.66016 8.11398 7.66016C9.82065 7.60016 11.1607 6.2135 11.1673 4.50016C11.1673 2.7535 9.74732 1.3335 8.00065 1.3335Z"
        fill={fill}
      />
      <path
        d="M11.3866 9.43342C9.52664 8.19342 6.49331 8.19342 4.61997 9.43342C3.77331 10.0001 3.30664 10.7668 3.30664 11.5868C3.30664 12.4068 3.77331 13.1668 4.61331 13.7268C5.54664 14.3534 6.77331 14.6668 7.99997 14.6668C9.22664 14.6668 10.4533 14.3534 11.3866 13.7268C12.2266 13.1601 12.6933 12.4001 12.6933 11.5734C12.6866 10.7534 12.2266 9.99342 11.3866 9.43342ZM9.33331 12.0868H8.49997V12.9201C8.49997 13.1934 8.27331 13.4201 7.99997 13.4201C7.72664 13.4201 7.49997 13.1934 7.49997 12.9201V12.0868H6.66664C6.39331 12.0868 6.16664 11.8601 6.16664 11.5868C6.16664 11.3134 6.39331 11.0868 6.66664 11.0868H7.49997V10.2534C7.49997 9.98009 7.72664 9.75342 7.99997 9.75342C8.27331 9.75342 8.49997 9.98009 8.49997 10.2534V11.0868H9.33331C9.60664 11.0868 9.83331 11.3134 9.83331 11.5868C9.83331 11.8601 9.60664 12.0868 9.33331 12.0868Z"
        fill={fill}
      />
    </svg>
  );
}
