import React from "react";

/**
 *
 * @param {{width: number | string, height: number | string, fill: string}} props
 * @returns
 */
const Clipboard = ({ width = 24, height = 24, fill = "#12355A" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M14.3498 2H9.64977C8.60977 2 7.75977 2.84 7.75977 3.88V4.82C7.75977 5.86 8.59977 6.7 9.63977 6.7H14.3498C15.3898 6.7 16.2298 5.86 16.2298 4.82V3.88C16.2398 2.84 15.3898 2 14.3498 2Z"
        fill={fill}
      />
      <path
        d="M17.2391 4.81998C17.2391 6.40998 15.9391 7.70998 14.3491 7.70998H9.64906C8.05906 7.70998 6.75906 6.40998 6.75906 4.81998C6.75906 4.25998 6.15906 3.90998 5.65906 4.16998C4.24906 4.91998 3.28906 6.40998 3.28906 8.11998V17.53C3.28906 19.99 5.29906 22 7.75906 22H16.2391C18.6991 22 20.7091 19.99 20.7091 17.53V8.11998C20.7091 6.40998 19.7491 4.91998 18.3391 4.16998C17.8391 3.90998 17.2391 4.25998 17.2391 4.81998ZM12.3791 16.95H7.99906C7.58906 16.95 7.24906 16.61 7.24906 16.2C7.24906 15.79 7.58906 15.45 7.99906 15.45H12.3791C12.7891 15.45 13.1291 15.79 13.1291 16.2C13.1291 16.61 12.7891 16.95 12.3791 16.95ZM14.9991 12.95H7.99906C7.58906 12.95 7.24906 12.61 7.24906 12.2C7.24906 11.79 7.58906 11.45 7.99906 11.45H14.9991C15.4091 11.45 15.7491 11.79 15.7491 12.2C15.7491 12.61 15.4091 12.95 14.9991 12.95Z"
        fill={fill}
      />
    </svg>
  );
};

export default Clipboard;
