import React from "react";

export default function SupportIcon({
  width = "24",
  height = "24",
  fill = "#999999",
  opacity = "0.56",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M13.1788 11.8606C12.7788 11.8606 12.4188 11.6406 12.2488 11.2806L10.7988 8.39058L10.3788 9.17058C10.1488 9.60058 9.68875 9.87058 9.19875 9.87058H8.46875C8.05875 9.87058 7.71875 9.53058 7.71875 9.12058C7.71875 8.71058 8.05875 8.37058 8.46875 8.37058H9.10875L9.89875 6.91058C10.0887 6.57058 10.4688 6.34058 10.8288 6.36058C11.2188 6.36058 11.5688 6.59058 11.7488 6.93058L13.1788 9.79058L13.5188 9.10058C13.7488 8.64058 14.1988 8.36058 14.7188 8.36058H15.5287C15.9388 8.36058 16.2788 8.70058 16.2788 9.11058C16.2788 9.52058 15.9388 9.86058 15.5287 9.86058H14.8187L14.1087 11.2706C13.9288 11.6406 13.5788 11.8606 13.1788 11.8606Z"
          fill={fill}
        />
        <path
          d="M2.75177 18.6508C2.34177 18.6508 2.00177 18.3108 2.00177 17.9008V12.2008C1.95177 9.49078 2.96177 6.93078 4.84177 5.01078C6.72177 3.10078 9.24177 2.05078 11.9518 2.05078C17.4918 2.05078 22.0018 6.56078 22.0018 12.1008V17.8008C22.0018 18.2108 21.6618 18.5508 21.2518 18.5508C20.8418 18.5508 20.5018 18.2108 20.5018 17.8008V12.1008C20.5018 7.39078 16.6718 3.55078 11.9518 3.55078C9.64177 3.55078 7.50177 4.44078 5.91177 6.06078C4.31177 7.69078 3.46177 9.86078 3.50177 12.1808V17.8908C3.50177 18.3108 3.17177 18.6508 2.75177 18.6508Z"
          fill={fill}
        />
        <path
          d="M5.94 12.4492H5.81C3.71 12.4492 2 14.1592 2 16.2592V18.1392C2 20.2392 3.71 21.9492 5.81 21.9492H5.94C8.04 21.9492 9.75 20.2392 9.75 18.1392V16.2592C9.75 14.1592 8.04 12.4492 5.94 12.4492Z"
          fill={fill}
        />
        <path
          d="M18.19 12.4492H18.06C15.96 12.4492 14.25 14.1592 14.25 16.2592V18.1392C14.25 20.2392 15.96 21.9492 18.06 21.9492H18.19C20.29 21.9492 22 20.2392 22 18.1392V16.2592C22 14.1592 20.29 12.4492 18.19 12.4492Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
