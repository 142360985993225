import {
  Box,
  Center,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { lowerCase } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaUnlockKeyhole } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import CutStructLogo from "../../assets/images/cutstructlogo.png";
import Button from "../../components/Button";
import Checkmark from "../../components/Checkmark/Checkmark";
import Input from "../../components/Input";
import {
  useLoginUserMutation,
  useVerifyEmailotpMutation,
} from "../../redux/api/auth/authSlice";
import instance from "../../utility/webservices";
import AuthLayout from "./AuthLayout";
import { handleError, handleSuccess } from "../../utility/helpers";
import { mixpanelIdentify, trackEvent } from "../../utility/mix-panel";
import CountDown from "../../components/CountDown";
import { useDispatch, useSelector } from "react-redux";
import { resetCountdown } from "../../redux/features/countdown/countdown";

const OTPScreen = ({
  email,
  password,
  fromSSO,
  userType,
  invitationId,
  projectId,
}) => {
  const [isLoadingResend, setLoadingResend] = useState(false);

  const history = useHistory();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { countdownTime, timerCompleted, isCountdownActive } = useSelector(
    (state) => state.countdown,
  );

  useEffect(() => {
    if (fromSSO) {
      onOpen();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerSchema = yup.object({
    otp: yup
      .string()
      .required("OTP is required")
      .min(6, "Must be exactly 6 characters"),
  });

  const methods = useForm({
    defaultValues: {
      otp: "",
    },
    resolver: yupResolver(registerSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [verifyEmailOtp, { isLoading, isSuccess, error, isError, data }] =
    useVerifyEmailotpMutation();

  // 👇 Calling the Login Mutation
  const [
    loginUser,
    {
      isLoading: isLoginLoading,
      isSuccess: isLoginSuccess,
      error: errorLogin,
      isError: isLoginError,
      data: loginData,
    },
  ] = useLoginUserMutation();

  const onSubmit = ({ otp }) => {
    verifyEmailOtp({
      emailOtp: Number(otp),
      email,
    });
  };

  const handleRessendOtp = async () => {
    setLoadingResend(true);
    try {
      await instance.post(`user/request-email-otp/${email ? email : "null"}`, {
        email,
      });
      setLoadingResend(false);
      toast({ description: "OTP resent successfully", status: "success" });
      handleResetCountdown();
    } catch (error) {
      handleError(error);
      setLoadingResend(false);
    }
  };

  const userVerified = async () => {
    onOpen();
    setTimeout(() => {
      loginUser({ email, password });
    }, 1500);
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        description: "Successfully verified your email",
        status: "success",
      });

      trackEvent("verified_otp", { email });
      userVerified();
    }

    if (isError) {
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    const user_type = data?.data?.userType;
    const userPersona = lowerCase(userType ? userType : user_type)
      .replaceAll(" ", "-")
      .replaceAll("_", "-");

    if (isLoginSuccess) {
      handleSuccess("Registration successful");
      onClose();

      history.push(
        `/${userPersona}/dashboard?welcome=new&invId=${
          invitationId ?? ""
        }&prId=${projectId ?? ""}`,
      );

      mixpanelIdentify(loginData.data);
    }

    if (isLoginError) {
      handleError(errorLogin);
      onClose();
      history.push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginLoading]);

  const dispatch = useDispatch();

  const handleResetCountdown = () => {
    dispatch(resetCountdown(60));
  };

  return (
    <>
      <AuthLayout>
        <Flex direction="column" h="80vh" justify="space-around">
          <Box>
            <form
              style={{ padding: "70px 0 10px" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Heading color="#F5862E" fontSize={["24px", "34px"]}>
                Account Verification
              </Heading>
              <Text color="#999999" my="10px">
                An OTP has been sent to your email to complete your
                registration. If you don't see it, you may need to{" "}
                <Text as="span" color="primary" fontWeight={600}>
                  check your spam
                </Text>{" "}
                folder.
              </Text>
              <Box fontSize="14px" my="50px">
                <Box my={"20px"}>
                  <Text>
                    Enter OTP <span style={{ color: "red" }}>*</span>{" "}
                  </Text>
                  <Text fontSize="13" color="#999" mb="2">
                    Enter the OTP code sent to your registered email for
                    verification.
                  </Text>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="otp"
                    render={({ field: { onChange, value } }) => (
                      <Box w={"100%"}>
                        <Input
                          placeholder="Enter OTP"
                          value={value}
                          type="number"
                          onChange={(e) => {
                            if (e.target.value.length <= 6) {
                              onChange(e);
                            }
                          }}
                          leftIcon={<FaUnlockKeyhole />}
                        />

                        <div style={{ color: "red" }}>
                          {errors["otp"] ? errors["otp"]?.message : ""}
                        </div>
                      </Box>
                    )}
                  />
                </Box>
              </Box>
              <Button full isSubmit isLoading={isLoading}>
                Continue
              </Button>
            </form>

            <Box fontSize="15px" mt="30px">
              {/* <Flex justify="center" textAlign="center" mr={2} gap={2} mb={5}>
                {isCountdownActive && <span>OTP expires in </span>}
                <CountDown />
              </Flex> */}

              <Flex justify="center" textAlign="center" mr="2">
                Didn't recieve OTP?
                <ChakraButton
                  ml={2}
                  colorScheme="orange"
                  color="#F5862E"
                  isLoading={isLoadingResend}
                  // isDisabled={isCountdownActive}
                  variant="link"
                  onClick={handleRessendOtp}
                >
                  Resend OTP
                </ChakraButton>
              </Flex>

              <Text
                my="20px"
                onClick={() => history.go(0)}
                cursor="pointer"
                textAlign="center"
                color="primary"
                fontWeight={500}
                textDecoration="underline"
              >
                Go Back
              </Text>
            </Box>
          </Box>
        </Flex>
      </AuthLayout>

      <Modal
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        isOpen={isOpen}
        size={"sm"}
        onClose={onClose}
      >
        <ModalOverlay
          bg="none"
          backdropFilter="auto"
          backdropInvert="20%"
          backdropBlur="3px"
        />
        <ModalContent p="30px">
          <Center
            bg="#ffffff"
            bgImage={CutStructLogo}
            bgRepeat="no-repeat"
            bgSize="cover"
            bgBlendMode="lighten"
            bgColor="rgba(255,255,255,.97)"
          >
            <Box>
              <Checkmark />
              <Center>
                <Heading fontSize="20px">Email verified</Heading>
              </Center>
            </Box>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OTPScreen;
