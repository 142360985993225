import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import React, { Component } from "react";
import ErrorImage from "../assets/images/404-error.png";
import Button from "../components/Button";
import { Link } from "react-router-dom/cjs/react-router-dom";

class errorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  user = JSON.parse(localStorage.getItem("userInfo"));
  persona = this.user?.userType?.replaceAll("_", "-").toLowerCase();

  handleRefresh = () => {
    window.location.reload();
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return (
        <Center h={"calc(100vh - 180px)"}>
          <Box>
            <Image src={ErrorImage} alt="Something went wrong" />
            <Text fontWeight={700} textAlign="center" fontSize="30px">
              Something went wrong
            </Text>

            <Flex justify="center" mt={5}>
              <Flex gap={10}>
                <Button link={`/${this.persona}/dashboard/`}>
                  Back to dashboard
                </Button>
                <Button onClick={this.handleRefresh}>Refresh page</Button>
              </Flex>
            </Flex>

            <Box textAlign="center" mt="20px">
              If this issue persists,{" "}
              <Link
                style={{
                  color: "#F5862E",
                  marginLeft: "5px",
                  cursor: "pointer",
                  transition: "font-weight ease",
                }}
                onMouseEnter={(e) => {
                  e.target.style.fontWeight = "600";
                }}
                onMouseLeave={(e) => {
                  e.target.style.fontWeight = "normal";
                }}
                to={"/support"}
              >
                contact Support
              </Link>
            </Box>
          </Box>
        </Center>
      );
    }

    return this.props.children;
  }
}

export default errorBoundary;
