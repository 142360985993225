import React from "react";

export default function EmptyStateIcon({
  fill = "#999999",
  width = "80",
  height = "73",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.56">
        <path
          d="M25.8359 10.5115V6.00642C25.8359 4.77505 24.7026 3.75391 23.3359 3.75391C21.9693 3.75391 20.8359 4.77505 20.8359 6.00642V10.6917C21.6693 10.6016 22.4359 10.5115 23.3359 10.5115H25.8359Z"
          fill={fill}
        />
        <path
          d="M52.5 10.6917V6.00642C52.5 4.77505 51.3667 3.75391 50 3.75391C48.6333 3.75391 47.5 4.77505 47.5 6.00642V10.5115H50C50.9 10.5115 51.6667 10.6016 52.5 10.6917Z"
          fill={fill}
        />
        <path
          d="M70.9641 44.9601C68.3974 43.1281 65.1641 42.0469 61.6641 42.0469C58.1641 42.0469 54.8641 43.1581 52.2974 45.0202C48.8307 47.4829 46.6641 51.3272 46.6641 55.562C46.6641 58.0848 47.4641 60.5175 48.8307 62.4997C49.8974 64.0615 51.2641 65.443 52.8974 66.4942C55.3641 68.116 58.3641 69.0771 61.6641 69.0771C65.4641 69.0771 68.8974 67.8157 71.5307 65.7133C72.6974 64.8423 73.6974 63.7611 74.4974 62.5298C75.8641 60.5175 76.6641 58.0848 76.6641 55.562C76.6641 51.2672 74.4307 47.4229 70.9641 44.9601ZM61.6641 61.9892C61.6641 58.4452 58.4641 55.562 54.5307 55.562C58.4641 55.562 61.6641 52.6788 61.6641 49.1348C61.6641 52.6788 64.8641 55.562 68.7974 55.562C64.8641 55.562 61.6641 58.4452 61.6641 61.9892Z"
          fill={fill}
        />
        <path
          d="M52.4974 10.6919V15.0168C52.4974 16.2481 51.3641 17.2693 49.9974 17.2693C48.6307 17.2693 47.4974 16.2481 47.4974 15.0168V10.5117H25.8307V15.0168C25.8307 16.2481 24.6974 17.2693 23.3307 17.2693C21.9641 17.2693 20.8307 16.2481 20.8307 15.0168V10.6919C10.9974 11.5028 6.66406 17.2092 6.66406 25.5285V51.057C6.66406 60.0671 11.6641 66.0738 23.3307 66.0738H37.0974C39.6307 66.0738 41.3307 63.5209 40.6974 61.2985C40.2307 59.6766 39.9974 57.9948 39.9974 56.3129C39.9974 50.0659 43.0974 44.2995 48.4641 40.4852C52.3641 37.632 57.3641 36.0402 62.4974 36.0402H62.6307C64.7307 36.0402 66.6641 34.6587 66.6641 32.7666V25.5285C66.6641 17.2092 62.3307 11.5028 52.4974 10.6919ZM29.9974 50.3062H23.3307C21.9641 50.3062 20.8307 49.285 20.8307 48.0537C20.8307 46.8223 21.9641 45.8011 23.3307 45.8011H29.9974C31.3641 45.8011 32.4974 46.8223 32.4974 48.0537C32.4974 49.285 31.3641 50.3062 29.9974 50.3062ZM39.9974 35.2894H23.3307C21.9641 35.2894 20.8307 34.2683 20.8307 33.0369C20.8307 31.8055 21.9641 30.7844 23.3307 30.7844H39.9974C41.3641 30.7844 42.4974 31.8055 42.4974 33.0369C42.4974 34.2683 41.3641 35.2894 39.9974 35.2894Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
