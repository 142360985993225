import mixpanel from "mixpanel-browser";

// const mixpanelToken = "";
const mixpanelToken = process.env.REACT_APP_MIX_PANEL_TOKEN;

const initializeMixpanel = () => {
  mixpanel.init(mixpanelToken, {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
    ignore_dnt: true,
  });
};

// This is for tracking different events, pass name of event and the properties which is an object.
const trackEvent = (eventName, properties = {}) => {
  if (mixpanelToken && process.env.REACT_APP_ENV === "production") {
    mixpanel.track(eventName, properties);
  } else {
    return console.warn(
      "No mixpanel token: Mixpanel is not initialized. Tracking event skipped.",
    );
  }
};

const mixpanelIdentify = (properties) => {
  const {
    email,
    id,
    businessName,
    userName,
    userType,
    phoneNumber,
    userId,
    status,
  } = properties;

  if (mixpanelToken && process.env.REACT_APP_ENV === "production") {
    mixpanel.identify(userId ? userId : id);

    const peopleProperties = {
      "Business Name": businessName || userName,
      "Full Name": userName,
      Email: email,
      "User Type": userType,
      "Phone Number": phoneNumber,
      "Accept Terms of Use": status === "ACTIVE" ? true : false,
      "Verified Email": status === "ACTIVE" ? true : false,
    };

    if (id || userId) {
      mixpanel.people.set(peopleProperties);
    }
  }
};

const resetMixpanel = () => {
  if (mixpanelToken) {
    mixpanel.reset();
  }
};

export { trackEvent, resetMixpanel, mixpanelIdentify };

export default initializeMixpanel;
