import React from "react";

export default function Vault({
  width = "24",
  height = "24",
  fill = "#999999",
  opacity = "0.56",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M21.9963 12.6196V14.6796C21.9963 15.2396 21.5363 15.6996 20.9663 15.6996H19.0363C17.9563 15.6996 16.9663 14.9096 16.8763 13.8296C16.8163 13.1996 17.0563 12.6096 17.4763 12.1996C17.8463 11.8196 18.3563 11.5996 18.9163 11.5996H20.9663C21.5363 11.5996 21.9963 12.0596 21.9963 12.6196Z"
          fill={fill}
        />
        <path
          d="M15.38 13.9604C15.29 12.9104 15.67 11.8804 16.43 11.1304C17.07 10.4804 17.96 10.1004 18.92 10.1004H19.49C19.77 10.1004 20 9.87039 19.96 9.59039C19.69 7.65039 18.01 6.15039 16 6.15039H6C3.79 6.15039 2 7.94039 2 10.1504V17.1504C2 19.3604 3.79 21.1504 6 21.1504H16C18.02 21.1504 19.69 19.6504 19.96 17.7104C20 17.4304 19.77 17.2004 19.49 17.2004H19.04C17.14 17.2004 15.54 15.7804 15.38 13.9604ZM13 11.9004H7C6.59 11.9004 6.25 11.5704 6.25 11.1504C6.25 10.7304 6.59 10.4004 7 10.4004H13C13.41 10.4004 13.75 10.7404 13.75 11.1504C13.75 11.5604 13.41 11.9004 13 11.9004Z"
          fill={fill}
        />
        <path
          d="M14.2067 3.97961C14.4667 4.24961 14.2367 4.64961 13.8567 4.64961H6.02673C4.93673 4.64961 3.91673 4.96961 3.06673 5.51961C2.67673 5.76961 2.14673 5.49961 2.33673 5.06961C2.89673 3.75961 4.20673 2.84961 5.71673 2.84961H11.3367C12.4967 2.84961 13.5267 3.25961 14.2067 3.97961Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
