import { handleError, handleSuccess } from "../../utility/helpers";
import { trackEvent } from "../../utility/mix-panel";
import instance from "../../utility/webservices";

const user = JSON.parse(localStorage.getItem("userInfo"));

const PayWithVault = async ({
  amount,
  orderId,
  projectId,
  paymentPurpose,
  setLoading,
  onCloseModal,
  refresh,
  paymentEventTracking,
}) => {
  setLoading(true);

  try {
    await instance.post(`/payment/vault/pay`, {
      amount,
      paymentType: paymentPurpose,
      projectId,
      orderId: orderId ?? "",
    });

    setLoading(false);
    handleSuccess("Payment successful");
    refresh();
    onCloseModal();
    paymentEventTracking();
    trackEvent("vault_balance_managed", user);
  } catch (error) {
    handleError(error);
    setLoading(false);
  }
};

const PayWithProjectWallet = async ({
  amount,
  orderId,
  projectId,
  paymentPurpose,
  setLoading,
  onCloseModal,
  refresh,
  paymentEventTracking,
}) => {
  setLoading(true);

  try {
    const { data } = await instance.post(`/payment/project/pay`, {
      paymentPurpose,
      orderId: orderId ?? "",
      projectId,
    });

    if (data.message === "Success") {
      await instance.post("/webhook/project/verify-transactions", data?.data);

      handleSuccess("Payment successful");
      refresh();
      onCloseModal();
      paymentEventTracking();
      trackEvent("vault_balance_managed", user);
    }

    return setLoading(false);
  } catch (error) {
    handleError(error);
    setLoading(false);
  }
};

export { PayWithVault, PayWithProjectWallet };
