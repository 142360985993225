import React from "react";

export default function UserOctagon({
  fill = "#fff",
  width = "16",
  height = "16",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.0053 3.90065L9.04531 1.61398C8.39865 1.24065 7.59865 1.24065 6.94531 1.61398L2.99198 3.90065C2.34531 4.27398 1.94531 4.96732 1.94531 5.72065V10.2807C1.94531 11.0273 2.34531 11.7207 2.99198 12.1007L6.95198 14.3873C7.59865 14.7607 8.39865 14.7607 9.05198 14.3873L13.012 12.1007C13.6586 11.7273 14.0586 11.034 14.0586 10.2807V5.72065C14.052 4.96732 13.652 4.28065 13.0053 3.90065ZM7.99865 4.89398C8.85865 4.89398 9.55198 5.58732 9.55198 6.44732C9.55198 7.30732 8.85865 8.00065 7.99865 8.00065C7.13865 8.00065 6.44531 7.30732 6.44531 6.44732C6.44531 5.59398 7.13865 4.89398 7.99865 4.89398ZM9.78531 11.1073H6.21198C5.67198 11.1073 5.35865 10.5073 5.65865 10.0607C6.11198 9.38732 6.99198 8.93398 7.99865 8.93398C9.00531 8.93398 9.88531 9.38732 10.3386 10.0607C10.6386 10.5007 10.3186 11.1073 9.78531 11.1073Z"
        fill={fill}
      />
    </svg>
  );
}
